/**
 *
 * HCP Index Page
 *
 */
import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { Link } from "gatsby"
import Layout from '../../components/dfa-theme/layout/hcp'
import myEmitter from '../../lib/emitter'
import imgHeadline from '../../images/hero-headline-hcp.png'
import imgWHO from '../../images/who-ladder-img.png'
import imgPill from '../../images/pill-splash-img.png'
import imgClock from '../../images/clocks-img.png'
import imgMeanPain from '../../images/mean-pain-chart.png'
import imgResMedChart from '../../images/rescue-med-chrt.png'
import { withPrefix } from 'gatsby-link'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import PageIndicator from '../../components/page-indicator/hcp'
import { pushEventGA }  from '../../lib/util.helper'

import '../../scss/indexpage-hcp.scss'

let el_top = 0
let el_bottom = 0
let el = null
let elHeight = 0
let elOffset = 0
let winHeight = 0
let elHome = ""
let elHeader = ""
let ACTIVE_MENU_ON_SCROLL = null; //onscroll

const scrollSections = [
  'home',
  'about-zipsor',
  'why-zipsor',
  'zipsor-copay',
]

const referencesBlock = 
[
  <div className="reference-block">
    <strong>REFERENCES</strong>: <strong>1.</strong> Vargas-Schaffer G.
    Is the WHO analgesic ladder still valid? Twenty-four years of
    experience. <i> Can Fam Physician</i>. 2010;56(6):514-517.{' '}
    <strong>2.</strong> Centers for Disease Control and Prevention.
    Opioids for acute pain: what you need to know.{' '}
    <a href="https://www.cdc.gov/drugoverdose/pdf/patients/Opioids-for-Acute-Pain-a.pdf" target="_blank" rel="noopener noreferrer">
      https://www.cdc.gov/drugoverdose/pdf/patients/Opioids-for-Acute-Pain-a.pdf
    </a>
    . Accessed October 9, 2018. <strong>3.</strong> Lissy M, Scallion R,
    Stiff DD, Moore K. Pharmacokinetic comparison of an oral diclofenac
    potassium liquid-filled soft gelatin capsule with a diclofenac
    potassium tablet. Expert Opin Pharmacother. 2010;11(5):701-708.{' '}
    <strong>4.</strong> Cole BE. Treating mild to moderate acute pain
    with oral diclofenac potassium liquid-filled capsules: rapid
    absorption with ProSorb dispersion technology.{' '}
    <i>Pain Medicine News.</i> 2011;March:1-8. <strong>5.</strong>{' '}
    Altman R, Bosch B, Brune K, Patrignani P, Young C. Advances in NSAID
    development: evolution of diclofenac products using pharmaceutical
    technology. <i>Drugs</i>. 2015;75(8):859-877. <strong>6.</strong>{' '}
    ZIPSOR [package insert]. Lake Forest, IL: Assertio Therapeutics,
    Inc.; 2016. <strong>7.</strong> Riff DS, Duckor S, Gottlieb I, et
    al. Diclofenac potassium liquid-filled soft gelatin capsules in the
    management of patients with postbunionectomy pain: a Phase III,
    multicenter, randomized, double-blind, placebo-controlled study
    conducted over 5 days. <i>Clin Ther</i>. 2009;31(10):2072-2085.{' '}
    <strong>8.</strong> Singla NK, Desjardins PJ, Chang PD. A comparison
    of the clinical and experimental characteristics of four acute
    surgical pain models: dental extraction, bunionectomy, joint
    replacement, and soft tissue surgery. <i>Pain</i>.
    2014;155(3):441-456.
  </div>
];

class IndexPage extends React.Component{

  state = {
    activesection: "home"
  }

  throttled = (delay, fn) => {
    let lastCall = 0
    return function(...args) {
      const now = new Date().getTime()
      if (now - lastCall < delay) {
        return
      }
      lastCall = now
      return fn(...args)
    }
  }


  handleScroll = () => {
    scrollSections.map(item => {
      el = document.getElementById(item)
      elHeight = el.clientHeight;
      elOffset = elHeight * 0.7;
      el_top = el.getBoundingClientRect().top
      el_bottom = el.getBoundingClientRect().bottom-elOffset
      if(el_top<=elHeight && el_bottom<=winHeight)
      {
        myEmitter.emit('UPDATE_ACTIVE_SECTION_ONSCROLL', item)
      }
    })
  }

  scrollListener = () => {
    this.throttled(500, this.handleScroll())
      if(elHeader.clientHeight === elHome.getBoundingClientRect().top) {
        myEmitter.emit('UPDATE_ACTIVE_SECTION_ONSCROLL', "home")
      }
  }

  componentDidMount() {
    winHeight = document.documentElement.clientHeight;
    elHome = document.getElementById("home")
    elHeader = document.getElementById("base-header")
    window.addEventListener('scroll',this.scrollListener);
  }

  componentWillMount() {
    ACTIVE_MENU_ON_SCROLL = myEmitter.addListener(`UPDATE_ACTIVE_SECTION_ONSCROLL`, (data) => {
      this.setState({
        activesection: data
      })
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollListener);
    ACTIVE_MENU_ON_SCROLL && ACTIVE_MENU_ON_SCROLL.remove();
  }

  render(){
    return(
      <Layout className="index-page hcp" referencesBlock={referencesBlock}>
        <Row className="hero-row" id="home">
          <Row className="inner-container" style={{margin:'auto'}}>
            <Col xs={12} sm={6}>

            </Col>
            <Col xs={12} sm={6}>
              <div className="hero-txt-content">
                <img src={imgHeadline} style={{width: '100%'}} alt="headline"/>
                <Link to="/hcp/get-in-touch/" className="btn-card" onClick={() => { pushEventGA('button','click','GET SAVINGS CARD') }}>GET SAVINGS CARD</Link>
                <p className="text-white">ZIPSOR<sup>&reg;</sup> (diclofenac potassium) is a prescription Non-Steroidal Anti-Inflammatory Drug (NSAID). ZIPSOR is used for relief of mild-to-moderate pain in adults (18 years of age and older).</p>
              </div>
            </Col>
          </Row>
        </Row>
          <Row id="about-zipsor">
            <Row className="content-block">
              <Col xs={12}>
                <p className="content-big-head text-blue">About ZIPSOR, a non-opioid approach</p>
                <p>World Health Organization (WHO) recommendations for pain relief<sup>1</sup></p>
                <ul>
                  <li>Start with non-opioid analgesics, including nonsteroidal anti-inflammatory drugs (NSAIDs)</li>
                  <li>Usage (with or without adjuvants) is suggested at every step in care</li>
                </ul>
                <div className="hr-block">
                  <hr/>
                  <img src={imgWHO} className="content-block-img" alt="WHO"/>
                </div>
                <p>The US government’s Centers for Disease Control and Prevention (CDC) recommends that<sup>2</sup>:</p>
                <ul>
                  <li>Acute pain may be managed without opioids</li>
                  <li>Non-opioid options include NSAIDs</li>
                  <li>Discuss with your patients about ways to relieve pain that do not involve prescription opioids</li>
                </ul>
                <p>NSAIDs have been used for pain for nearly 60 years and are widely prescribed and recommended by physicians.</p>
              </Col>
            </Row>
          </Row>
        <Row id="why-zipsor">
          <Row className="content-block">
            <Col xs={12}>
              <p className="content-big-head text-blue">Why ZIPSOR?</p>
              <p><strong>ProSorb<sup>®</sup> technology maximizes absorption<sup>3</sup></strong></p>
              <p>ZIPSOR is designed for rapid, consistent absorption<sup>3*</sup></p>
              <div style={{textAlign: "center"}}><img src={imgPill} className="content-block-img" alt="pill"/></div>
              <p>*Study conducted in healthy volunteers ≥ 18 years of age.</p>
              <div style={{textAlign: "center"}}><img src={imgClock} className="content-block-img" alt="clock"/></div>
              <hr/>
              <div style={{textAlign: "center"}}><img src={imgMeanPain} className="content-block-img" alt="Mean Pain"/></div>
              <p><strong>Significant pain reduction with every dose<sup>6,7</sup></strong></p>
              <p>Primary endpoint: pain reduction over 48 hours after bunionectomy<sup>6,7</sup></p>
              <p>Bunionectomy surgery results in considerable pain and is a standard model for assessing efficacy of an acute pain medication.<sup>8</sup></p>
              <p><strong>Study Design:</strong> This Phase III, randomized, double-blind, parallel-group, placebo-controlled study was conducted in a single-dose period and a multiple-dose period. Patients with a qualifying level of postsurgical pain (score ≥4 on an 11-point NPRS from 0=no pain to 10=worst possible pain) were randomly assigned to receive ZIPSOR 25 mg or placebo soft gelatin capsules in a 1:1 ratio.<sup>7</sup></p>
              <div style={{textAlign: "center"}}><img src={imgResMedChart} className="content-block-img" style={{marginBottom: 20}} alt="Med Chart"/></div>
              <p style={{marginTop:0, marginBottom: 80}}>Use of rescue medication (1-2 hydrocodone/acetaminophen tablets) during the inpatient 48-hour multiple-dose period after bunionectomy in patients who received ZIPSOR 25 mg or placebo. Day 3 was not a full day, and the data reflect use of rescue medication only up to the time of discharge.<sup>7</sup></p>
            </Col>
          </Row>
        </Row>
        <Row className="copay-row" id="zipsor-copay">
          <Row className="content-block">
            <Col xs={12}>
              <p className="content-big-head">Your patients can save on ZIPSOR</p>
              <p>Your patients can save money on ZIPSOR by downloading a copay card.*</p>
              <p>*Terms and conditions may apply. View savings card for full terms and conditions.</p>
              <div style={{textAlign: 'center'}}><a href={withPrefix('/pdf/copay-card.pdf')} target="_blank" rel="noopener noreferrer" className="btn-card" onClick={() => { pushEventGA('button','click','DOWNLOAD COPAY CARD') }}>DOWNLOAD COPAY CARD</a></div>
              <p><strong>Please see <AnchorLink href="#isi" className="custom-isi-orangelink" onClick={() => { pushEventGA('link','click','EXPAND FULL ISI') }}>Important Safety Information</AnchorLink> and full <a href={withPrefix('/pdf/prescribing-information.pdf')} target="_blank" rel="noopener noreferrer" className="custom-isi-orangelink" onClick={() => { pushEventGA('link','click','DOWNLOAD PRESCRIBING INFORMATION') }}>Prescribing Information</a>, including BOXED WARNING.</strong></p>
            </Col>
          </Row>
        </Row>
        <PageIndicator theme={["home","why-an-nsaid","zipsor-copay"].includes(this.state.activesection) ? "light" : "dark" }/>
      </Layout>
    )
  }
}




export default IndexPage
