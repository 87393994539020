/**
 *
 * HCP Page indicator component
 *
 */

import React from 'react'
import myEmitter from '../../../lib/emitter'
import Indicator from './Indicator'
import '../index.scss'

let ACTIVE_MENU_ON_SCROLL = null; //onscroll

class PageIndicator extends React.Component{
    state = {
        activesection: "home"
    }
    componentWillMount(){
        ACTIVE_MENU_ON_SCROLL = myEmitter.addListener(`UPDATE_ACTIVE_SECTION_ONSCROLL`, (data) => {
            this.setState({
                activesection: data
            })
          });
    }
    componentWillUnmount() {
        ACTIVE_MENU_ON_SCROLL && ACTIVE_MENU_ON_SCROLL.remove();
    }

    render(){
        return (
            <div className={`list-indicators ${this.props.theme}`}>
              <Indicator href="#home" caption="HOME" status={this.state.activesection==="home" && 'active'}/>
              <Indicator href="#about-zipsor" caption="ABOUT ZIPSOR" status={this.state.activesection==="about-zipsor" && 'active'}/>
              <Indicator href="#why-zipsor" caption="Why ZIPSOR?" status={this.state.activesection==="why-zipsor" && 'active'}/>
              <Indicator href="#zipsor-copay" caption="ZIPSOR COPAY" status={this.state.activesection==="zipsor-copay" && 'active'}/>
            </div>
            )
    }

}

export default PageIndicator